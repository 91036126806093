import React from "react";
import useLinks from "@hooks/useLinks";
import { LINKS } from "@constants";
import { 
    StyledFooterLinks,
    StyledFooterLinksSectionFirst,
    StyledFooterLinksSectionSecond,
    StyledFooterLinksSectionContainer,
    StyledFooterLinksSectionWrapper,
    StyledFooterLinksSectionContainerFlex,
    StyledFooterLinksSectionContainerTitle
} from "./styled";

const LinksContainer = () => {
  const generateLinks = useLinks();
  return (
    <StyledFooterLinks>
      <StyledFooterLinksSectionFirst>
        <StyledFooterLinksSectionContainerFlex>
          <StyledFooterLinksSectionWrapper>
            <StyledFooterLinksSectionContainerTitle>
              Services
            </StyledFooterLinksSectionContainerTitle>
            {generateLinks(LINKS.services)}
          </StyledFooterLinksSectionWrapper>
          {/* <StyledFooterLinksSectionWrapper>
            <StyledFooterLinksSectionContainerTitle>
              Technologies
            </StyledFooterLinksSectionContainerTitle>
            {generateLinks(LINKS.subServices)}
          </StyledFooterLinksSectionWrapper> */}
        </StyledFooterLinksSectionContainerFlex>
        <StyledFooterLinksSectionContainer>
          <StyledFooterLinksSectionWrapper>
            <StyledFooterLinksSectionContainerTitle>
              Industry
            </StyledFooterLinksSectionContainerTitle>
            {generateLinks(LINKS.industry)}
          </StyledFooterLinksSectionWrapper>
        </StyledFooterLinksSectionContainer>
      </StyledFooterLinksSectionFirst>
      <StyledFooterLinksSectionSecond>
        <StyledFooterLinksSectionContainer>
          <StyledFooterLinksSectionWrapper>
            <StyledFooterLinksSectionContainerTitle>
              Expertise
            </StyledFooterLinksSectionContainerTitle>
            {generateLinks(LINKS.expertise)}
          </StyledFooterLinksSectionWrapper>
        </StyledFooterLinksSectionContainer>
        <StyledFooterLinksSectionContainer>
          <StyledFooterLinksSectionWrapper>
            <StyledFooterLinksSectionContainerTitle>
              Company
            </StyledFooterLinksSectionContainerTitle>
            {generateLinks(LINKS.company)}
            {generateLinks(LINKS.rest)}
          </StyledFooterLinksSectionWrapper>
        </StyledFooterLinksSectionContainer>
      </StyledFooterLinksSectionSecond>
    </StyledFooterLinks>
  );
};

export default LinksContainer;
