import styled from "styled-components"

export const StyledFooterWrapper = styled.footer`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 6.75rem;
  column-gap: 7.5rem;

  @media only screen and (max-width: 1350px) {
    margin-top: 3.5rem;
    position: relative;

    &__divider {
      display: none;
    }
}
`;

export const StyledFooterInfoContainer = styled.div`
    border-top: 0.0625rem solid rgba(255, 255, 255, 0.2);
    border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.2);
    padding: 2.5rem 0 2.75rem 0;
    margin: 2.5rem 0;
`;