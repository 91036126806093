import styled from "styled-components";
import { ReactSVG } from "react-svg";

export const StyledFooterInfoWrapper = styled.div`
    @media only screen and (max-width: 769px) {
      width: 100%;
    }
`;

export const StyledFooterInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 769px) {
    flex-direction: column;
  }

  @media only screen and (min-width: 769px) and (max-width: 1350px) {
    flex-direction: column;
  }
`;

export const StyledFooterCompanyContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;

    & h2 {
      margin: 0;
      color: white;
    }
`;

export const StyledFooterCompanyLogo = styled(ReactSVG)`
  display: flex;
  align-items: center;
  margin-right: 1.5rem;

  & svg {
    width: 1.063rem;
    height: 2.75rem;

  & path {
    fill: white;
    }
  }
`;

export const StyledFooterInfoBadgesContainer = styled.div`
  @media only screen and (max-width: 769px) {
    margin-top: 2.5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1.5rem;
  }

  @media only screen and (min-width: 769px) and (max-width: 1350px) {
    margin-top: 2.5rem;
  }
`;

export const StyledFooterInfoBadgeContainer = styled.a`
    margin-right: 1.1875rem;


    & img {
        width: 7.5rem;
        height: 7.5rem;
    }

    &:first-child img {
      height: 7.813rem;
    }

    &:nth-last-child(2) img {
      height: auto;
  }

    &:last-child {
      margin-right: 0;
    }

 @media only screen and (min-width: 769px) and (max-width: 1350px) {
    margin-right: 1.875rem;
  }
`;
