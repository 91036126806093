import React from "react";
import logo from "@images/impressit-logo.svg";
import { StaticImage } from "gatsby-plugin-image";
import { GLOBAL_APPLICATION_EXTERNAL_LINKS } from "@links";
import {
    StyledFooterInfoWrapper,
    StyledFooterInfoContainer,
    StyledFooterCompanyContainer,
    StyledFooterCompanyLogo,
    StyledFooterInfoBadgesContainer,
    StyledFooterInfoBadgeContainer
} from "./styled";

const FooterInfo = () => (
    <StyledFooterInfoWrapper>
        <StyledFooterInfoContainer>
            <StyledFooterCompanyContainer>
                <StyledFooterCompanyLogo
                    src={logo}
                    alt="impressit logo"
                />
                <h2>impressit</h2>
            </StyledFooterCompanyContainer>
            <StyledFooterInfoBadgesContainer>
                <StyledFooterInfoBadgeContainer
                    target="_blank"
                    href={GLOBAL_APPLICATION_EXTERNAL_LINKS.CLUTCH_IMPRESSIT}
                    rel="nofollow noreferrer"
                >
                    <StaticImage
                        src="../../../../../images/common/clutch-global-spring-logo.svg"
                        alt="clutch-global-spring"
                    />
                </StyledFooterInfoBadgeContainer>
                <StyledFooterInfoBadgeContainer
                    target="_blank"
                    href={GLOBAL_APPLICATION_EXTERNAL_LINKS.GOODFIRMS_IMPRESSIT}
                    rel="nofollow noreferrer"
                >
                    <StaticImage
                        src="../../../../../images/common/good-firms.svg"
                        alt="good-firms"
                    />
                </StyledFooterInfoBadgeContainer>
                <StyledFooterInfoBadgeContainer
                    target="_blank"
                    href={GLOBAL_APPLICATION_EXTERNAL_LINKS.WADLINE_IMPRESSIT}
                    rel="nofollow noreferrer"
                >
                    <StaticImage
                        src="../../../../../images/common/wadline-logo.svg"
                        alt="wadline"
                    />
                </StyledFooterInfoBadgeContainer>
                <StyledFooterInfoBadgeContainer
                    target="_blank"
                    href={GLOBAL_APPLICATION_EXTERNAL_LINKS.UPCITY_IMPRESSIT}
                    rel="nofollow noreferrer"
                >
                    <StaticImage
                        quality={100}
                        src="../../../../../images/common/upcity-logo.svg"
                        alt="upcity"
                    />
                </StyledFooterInfoBadgeContainer>
            </StyledFooterInfoBadgesContainer>
        </StyledFooterInfoContainer>
    </StyledFooterInfoWrapper>
);

export default FooterInfo;
