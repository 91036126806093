import React from "react";
import LinksContainer from "./components/LinksContainer/LinksContainer";
import FooterInfo from "./components/FooterInfo/FooterInfo";
import { FooterSocialMediaInfo } from "./components";
import { StyledFooterWrapper, StyledFooterInfoContainer } from "./styled";

const Footer = () => (
    <StyledFooterWrapper>
        <FooterInfo />
        <StyledFooterInfoContainer>
            <LinksContainer />
        </StyledFooterInfoContainer>
        <FooterSocialMediaInfo />
    </StyledFooterWrapper>
);

export default Footer;
