import React from "react"
import { LINKS } from "@constants"
import useLinks from "@hooks/useLinks"
import useWindow from "@hooks/useWindow"
import useWindowDimensions from "@hooks/useWindowDimensions"
import RightArrow from "@images/common/right-arrow-solid.svg"
import {
  LinkContainer,
  FirstLinksSection,
  SectionTitle,
  SecondaryLinksContainer,
  SecondLinksSection,
  ThirdLinksSection,
  FourthLinksSection,
  MainLinksContainer,
  // SubServicesLinks,
} from "../styles/LinksContainer.styles"
import { ReactSVG } from "react-svg"

const LinksContainer = ({ setShowLinks, path }) => {
  const generateLinks = useLinks()
  const isWindowDefined = useWindow()
  const { isDesktop } = useWindowDimensions()

  const thirdLinksContainerMarkup = (
    <ThirdLinksSection>
      <SectionTitle>Company</SectionTitle>
      {generateLinks(LINKS.company, false, path, setShowLinks)}
    </ThirdLinksSection>
  )
  return (
    <LinkContainer>
      <FirstLinksSection isWindowDefined={isWindowDefined}>
        <MainLinksContainer>
          <SectionTitle>Services</SectionTitle>
          {isDesktop ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {generateLinks(
                LINKS.services,
                true,
                path,
                setShowLinks,
                <ReactSVG
                  src={RightArrow}
                />
              )}
            </div>
          ) : (
            <>{generateLinks(LINKS.services, true, path, setShowLinks)}</>
          )}
          {/* comment for now */}
          {/* <SubServicesLinks> */}
          {/*    {generateLinks( */}
          {/*        LINKS.subServices, */}
          {/*        false, */}
          {/*        path, */}
          {/*        setShowLinks */}
          {/*    )} */}
          {/* </SubServicesLinks> */}
        </MainLinksContainer>
        {!isDesktop && thirdLinksContainerMarkup}
      </FirstLinksSection>
      <SecondaryLinksContainer isWindowDefined={isWindowDefined}>
        <SecondLinksSection>
          <SectionTitle>Expertise</SectionTitle>
          {generateLinks(LINKS.expertise, false, path, setShowLinks)}
        </SecondLinksSection>
        {isDesktop && thirdLinksContainerMarkup}
        <FourthLinksSection>
          <SectionTitle>Industry</SectionTitle>
          {generateLinks(LINKS.industry, false, path, setShowLinks)}
        </FourthLinksSection>
      </SecondaryLinksContainer>
    </LinkContainer>
  )
}

export default LinksContainer
