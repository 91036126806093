import styled from "styled-components";

export const StyledFooterLinks = styled.div`
  display: flex;
  justify-content: flex-start;

  p {
    white-space: nowrap;
  }

  @media only screen and (max-width: 580px) {
    justify-content: center;
    flex-direction: column;
    margin-left: 1.5rem;
  }

  @media only screen and (min-width: 580px) and (max-width: 1150px) {
    flex-direction: column;
  }
`;

const StyledFooterLinksSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;

  @media only screen and (max-width: 580px) {
    flex-direction: column;
  }
`;

export const StyledFooterLinksSectionFirst = styled(StyledFooterLinksSection)`
  justify-content: space-between;

  @media only screen and (min-width: 580px) and (max-width: 1150px) {
    width: 100%;
  }
`;

export const StyledFooterLinksSectionSecond = styled(StyledFooterLinksSection)`
  justify-content: space-around;

  @media only screen and (max-width: 580px) {
    margin-top: 2.75rem;
  }

  @media only screen and (min-width: 580px) and (max-width: 1150px) {
    justify-content: normal;
    width: 100%;
  }

  @media only screen and (max-width: 1250px) and (min-width: 1151px) {
    justify-content: space-evenly;
  }

`;

export const StyledFooterLinksSectionContainer = styled.div`
  margin-right: 2.5rem;

  &:last-child {
  margin-right: 0;
  }

  @media only screen and (max-width: 580px) {
    margin-bottom: 2.75rem;
     &:last-child {
       margin: 0;
     }
    }

  @media only screen and (min-width: 580px) and (max-width: 1150px) {
    width: 50%;
    display: flex;
    justify-content: center;
      &:last-child {
         margin-right: 0;
      }
    }
`;

export const StyledFooterLinksSectionWrapper = styled.div`

  @media only screen and (min-width: 580px) and (max-width: 1150px) {
    width: 15rem;
  }

`;

export const StyledFooterLinksSectionContainerFlex = styled(StyledFooterLinksSectionContainer)`

  @media only screen and (max-width: 580px) {
    display: flex;
    row-gap: 2.75rem;
  }

  @media only screen and (max-width: 1150px) {
    flex-wrap: wrap;

     p:last-child {
      margin-bottom: 0;
     }

   }

@media only screen and (min-width: 580px) and (max-width: 1150px) {
  margin-bottom: 1rem;
  & ${StyledFooterLinksSectionWrapper}:nth-child(2) {
    margin-top: 1.1rem;
  }
}
`;

export const StyledFooterLinksSectionContainerTitle = styled.p`
  color: #FFFFFF4D;
  font-weight: 600;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  margin: 0;
`;