import React, { useCallback } from "react";
import "./ContactSection.scss";
import Button from "@common/Button/Button";
// import chatIcon from "@images/common/ic-chat.svg";
import arrowRight from "@images/common/ic-arrow-right.svg";
import { DEFAULT_SIZE_BUTTON, SECONDARY_BUTTON } from "@constants";
import { navigate } from "gatsby";
import PageHeading from "../PageHeading/PageHeading";

const ContactSection = ({ buttonText }) => {
    const handleClick = useCallback(() => {
        navigate("/contact-us");
    }, [navigate]);

    return (
        <div className="contact-section">
            <div className="contact-section__info">
                <PageHeading
                    heading="Say hello to our team"
                    mainParagraph="Complete contact form, and we will contact you within 24 hours"
                />
            </div>
            <div className="contact-section__buttons-container">
                <div className="contact-section__button-container">
                    <Button
                        text={buttonText}
                        handleClick={handleClick}
                        svgPath={arrowRight}
                        size={DEFAULT_SIZE_BUTTON}
                        type={SECONDARY_BUTTON}
                    />
                </div>
                {/* <div className="contact-section__button-container"> */}
                {/*    <Button */}
                {/*        text="Let’s chat now" */}
                {/*        svgPath={chatIcon} */}
                {/*        type={MAIN_BUTTON} */}
                {/*    /> */}
                {/* </div> */}
            </div>
        </div>
    );
};

export default ContactSection;
